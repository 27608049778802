<template>
    <app-page no-container>
        <template #page-header>
            <page-header name="Firewalls" :loading="loading" v-model="search">
                <b-btn class="mb-2 mr-1" variant="secondary" @click="addIpModalVisible = true">Allow new IP<i class="fas fa-plus ml-1"></i></b-btn>
                <b-btn class="mb-2 mr-1" variant="secondary" @click="deleteExpiredRules">Delete expired rules<i class="fas fa-trash ml-1"></i></b-btn>
            </page-header>
        </template>
        <b-overlay :show="loading" rounded="sm" class="p-3">
            <h4>Your current IP ({{ currentIP }}) is <span class="highlight">highlighted</span></h4>
            <table class="table-sticky-header m-auto" v-if="!isMobile()">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>IP</th>
                    <th>Expiration</th>
                    <th v-for="firewall of firewalls" class="checkbox-column" :class="{'text-danger': firewall.error}">
                        <a :href="firewall.url" :class="{'text-danger': firewall.error}" target="_blank">{{ firewall.name }}</a>
                        <br>
                        <i :id="firewall._id+`-popover`" class="ml-1 fas fa-info-circle fa-sm"></i>
                        <b-popover :target="firewall._id+`-popover`" triggers="hover" placement="top">
                            <template #title>{{ firewall.name }}</template>
                            {{ firewall.description }}
                            <div class="text-danger" v-if="firewall.error">
                                <hr>
                                {{firewall.error}}
                            </div>
                        </b-popover>
                    </th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="rule of filteredRules" :class="{highlight: rule.ip === currentIP}">
                    <td>
                        <b-input-group v-if="rule.rename">
                            <b-input v-model="rule.name"></b-input>
                            <b-input-group-append>
                                <b-btn @click="renameRule(rule)" variant="success" title="Save" :disabled="saving"><i class="fas fa-save"></i></b-btn>
                                <b-btn @click="rule.rename = false; $forceUpdate()" title="cancel" :disabled="saving"><i class="fas fa-times"></i></b-btn>
                            </b-input-group-append>
                        </b-input-group>
                        <span v-else>{{ rule.name }} <i class="fas fa-edit" @click="rule.rename = true; $forceUpdate()"></i></span>
                    </td>
                    <td>{{ rule.ip }}</td>
                    <td>{{ rule.date_expiration|date }}</td>
                    <td v-for="firewall of firewalls" class="checkbox-column">
                        <b-checkbox v-model="rule.firewalls[firewall._id]" @change="addOrDeleteRule(firewall, rule)" :disabled="saving"></b-checkbox>
                    </td>
                    <td>
                        <e-button-confirm @confirmed="addRuleToAllFirewalls(rule)" v-b-tooltip title="Check All" class="mr-1" size="sm" variant="light" :disabled="saving"><i class="fas fa-check-double"></i></e-button-confirm>
                        <e-button-confirm @confirmed="deleteRuleFromAllFirewalls(rule)" v-b-tooltip title="Uncheck All" class="mr-1" size="sm" variant="light" :disabled="saving"><i class="fas fa-times"></i></e-button-confirm>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="accordion" role="tablist" v-else>
                <b-card no-body class="mb-1" v-for="rule in filteredRules" :key="rule.ip">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle="rule.ip" :variant="rule.ip === currentIP ? `info` : `secondary`">{{ rule.name }} <br>{{rule.ip}}</b-button>
                    </b-card-header>
                    <b-collapse :id="rule.ip" accordion="firewall-accordion" role="tabpanel">
                        <b-card-body>
                            <e-button-confirm @confirmed="addRuleToAllFirewalls(rule)" title="Check All" class="mr-2" size="sm" variant="success" :disabled="saving"><i class="fas fa-check"></i> Check all</e-button-confirm>
                            <e-button-confirm @confirmed="deleteRuleFromAllFirewalls(rule)" title="Uncheck All" size="sm" variant="danger" :disabled="saving"><i class="fas fa-times"></i> Uncheck all</e-button-confirm>
                            <b-row class="mt-2">
                                <b-col v-for="firewall of firewalls" :key="firewall._id" class="text-left" :class="{'text-danger': firewall.error}">
                                    <template v-if="firewall.error">
                                        <span :id="firewall._id+`-mobile-popover`">Error <i class="fas fa-info-circle" :title="firewall.error"></i></span>
                                        <b-popover :target="firewall._id+`-mobile-popover`" triggers="hover" placement="top">
                                            <template #title>{{ firewall.name }}</template>
                                            {{ firewall.description }}
                                            <div class="text-danger" v-if="firewall.error">
                                                <hr>
                                                {{firewall.error}}
                                            </div>
                                        </b-popover>
                                    </template>

                                    <b-form-group>
                                        <b-checkbox v-model="rule.firewalls[firewall._id]" @change="addOrDeleteRule(firewall, rule)" :disabled="saving || firewall.error">{{firewall.name}}</b-checkbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </b-overlay>

        <b-modal v-model="addIpModalVisible" title="Add/Remove new IP">
            <div class="text-center">
                <b-btn @click="useCurrentIP">Use current IP: {{ currentIP }}</b-btn>
            </div>
            <b-form-group label="Name">
                <b-input v-model="newRule.name"></b-input>
            </b-form-group>
            <b-form-group label="IP">
                <b-input v-model="newRule.ip"></b-input>
            </b-form-group>

            <div class="text-center">
                <b-btn @click="addAllFirewalls" class="mr-1">Add all firewalls</b-btn>
                <b-btn @click="removeAllFirewalls">Remove all firewalls</b-btn>
            </div>
            <b-form-group label="Firewalls">
                <vue-multiselect v-model="newRule.firewalls" :searchable="true" :multiple="true" :options="firewalls" :allowEmpty="false" label="name" track-by="_id">
                </vue-multiselect>
            </b-form-group>

            <b-form-group label="Expiration (optional)">
                <b-form-datepicker v-model="newRule.date_expiration" value-as-date :min="new Date()"></b-form-datepicker>
            </b-form-group>

            <template #modal-footer>
                <b-btn variant="default" @click="addIpModalVisible = false" :disabled="saving">Cancel</b-btn>

                <b-btn variant="primary" @click="addRule(newRule.firewalls, newRule)" :disabled="saving">
                    Create
                    <i class="fas fa-save"></i>
                </b-btn>
            </template>
        </b-modal>

        <b-modal v-model="showResults" title="Results">
            <table>
                <tr v-for="result of results">
                    <td>{{ result.name }}</td>
                    <td :class="{'text-danger': result.status === `rejected`, 'text-success': result.status === `fulfilled`}">{{ result.status === `fulfilled` ? `Ok` : ` Error` }}</td>
                    <td v-if="result.reason">{{ result.reason.message }}</td>
                    <td v-else>N/A</td>
                </tr>
            </table>
            <template #modal-footer>
                <b-btn variant="default" @click="showResults = false">Close</b-btn>
            </template>
        </b-modal>
    </app-page>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";
import EButtonConfirm from "../../vue-components/components/e-button-confirm.vue";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import moment from "moment";

export default {
    name: `firewalls`,
    components: {VueMultiselect, EButtonConfirm},
    data() {
        return {
            loading: true,
            rulesByIp: {},
            firewalls: [],
            addIpModalVisible: false,
            newRule: {
                firewalls: [],
                name: ``,
                ip: ``,
                date_expiration: null
            },
            currentIP: ``,
            saving: false,
            showResults: false,
            results: null,
            search: ``
        }
    },
    computed: {
        filteredRules() {
            if (!this.search) {
                return Object.values(this.rulesByIp);
            }

            return Object.values(this.rulesByIp).filter(r => r.name.toLowerCase().includes(this.search.toLowerCase()) || r.ip.includes(this.search.toLowerCase()))
        }
    },
    activated() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters.cluster,
            () => {
                this.init();
            }
        );

        this.init();
    },
    deactivated() {
        this.unwatch();
    },
    methods: {
        isMobile() {
            return window.screen.width <= 760;
        },
        init() {
            this.getFirewalls();
            Network.get(`https://api.eyein.media`, {external: true})
                .then(resp => {
                    this.currentIP = resp.data.ip;
                })
        },
        async getFirewalls() {
            this.loading = true;
            const resp = await Network.get(`/api/firewalls`)
            this.firewalls = resp.data.firewalls;
            this.rulesByIp = resp.data.rulesByIp;
            this.loading = false;
        },
        async addOrDeleteRule(firewall, rule) {
            this.saving = true;

            try {
                if (rule.firewalls[firewall._id]) {
                    await Network.post(`/api/firewall/rules/create`, {
                        firewalls: [firewall._id],
                        name: rule.name,
                        ip: rule.ip
                    })
                } else {
                    await Network.post(`/api/firewall/rules/delete`, {
                        firewalls: [firewall._id],
                        ip: rule.ip
                    })
                }
            } catch (e) {
                console.error(e);
            }

            this.saving = false;
        },
        async addRuleToAllFirewalls(rule) {
            return this.addRule(this.firewalls, rule);
        },
        async addRule(firewalls, rule) {
            this.saving = true;

            try {
                const resp = await Network.post(`/api/firewall/rules/create`, {
                    firewalls: firewalls.map(f => f._id),
                    name: rule.name,
                    ip: rule.ip,
                    date_expiration: rule.date_expiration
                })

                this.results = resp.data;
                this.addIpModalVisible = false;
                this.showResults = true;

                await this.getFirewalls();
            } catch (e) {
                console.error(e);
            }

            this.saving = false;
        },
        async deleteRuleFromAllFirewalls(rule) {
            return this.deleteRule(this.firewalls, rule);
        },
        async deleteRule(firewalls, rule) {
            this.saving = true;

            if (!rule.ip) {
                throw new Error(`Rule IP is empty`);
            }

            if (firewalls.length === 0) {
                throw new Error(`Firewalls list is empty`);
            }

            try {
                const resp = await Network.post(`/api/firewall/rules/delete`, {
                    firewalls: firewalls.map(f => f._id),
                    ip: rule.ip
                })

                this.results = resp.data;
                this.addIpModalVisible = false;
                this.showResults = true;

                await this.getFirewalls();
            } catch (e) {
                console.error(e);
            }

            this.saving = false;
        },
        async renameRule(rule) {
            this.saving = true;

            if (!rule.ip) {
                throw new Error(`Rule IP is empty`);
            }

            try {
                const resp = await Network.post(`/api/firewall/rules/rename`, {
                    firewalls: Object.keys(rule.firewalls),
                    ip: rule.ip,
                    name: rule.name
                })

                this.results = resp.data;
                this.addIpModalVisible = false;
                this.showResults = true;

                await this.getFirewalls();
            } catch (e) {
                console.error(e);
            }

            this.saving = false;
        },
        async deleteExpiredRules() {
            await Network.post(`/api/firewalls/rules/delete-expired`);
            await this.getFirewalls();
        },
        useCurrentIP() {
            this.newRule.ip = this.currentIP;
            this.newRule.name = `${this.$store.state.user.username}_${moment().format(`YYYY-MM-DD`)}`;
            this.newRule.date_expiration = null;
            this.addAllFirewalls();
        },
        addAllFirewalls() {
            this.newRule.firewalls = clone(this.firewalls);
        },
        removeAllFirewalls() {
            this.newRule.firewalls = [];
        }
    }
};
</script>


<style lang="scss" scoped>
.table-sticky-header {
    position: relative;
    border-collapse: separate;
    border-spacing: 0;

    $border: 1px solid black;

    th {
        position: sticky;
        top: 150px;
        border-top: $border;
        border-bottom: $border;
        border-right: $border;

        &:first-child {
            border-left: $border;
        }
    }

    td {
        border-bottom: $border;
        border-right: $border;

        &:first-child {
            border-left: $border;
        }
    }

    td, th {
        padding: 3px 5px;
        background-color: white;
        z-index: 5;
    }

    .checkbox-column {
        width: 100px;
    }
}

.highlight, .highlight td {
    background-color: #b6dbb6 !important;
}
</style>
